














































import { defineComponent } from "@vue/composition-api";
import { Button, Icon, List, PullRefresh, Search, Sticky, Image } from "vant";
import axios from "@/helpers/axios";

interface CardShipOrder {
  id: number;
  userName: string;
  userPhone: string;
  userAvatar: string;
  stateMsg: string;
  state: number;
  typeMsg: string;
  type: number;
  createTimeMsg: string;
  confirmTimeMsg: string;
}

export default defineComponent({
  components: {
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Image.name]: Image
  },
  data() {
    return {
      keywordRaw: "",
      keyword: "",
      state: 0,
      page: -1,
      size: 10,
      list: [] as CardShipOrder[],

      refreshing: false,
      finished: false,
      loading: false,
      empty: false
    };
  },
  methods: {
    search() {
      this.keyword = this.keywordRaw;
      this.onRefresh();
    },
    onRefresh() {
      this.page = -1;
      window.scrollTo(0, 0);
      this.getData();
    },
    onLoad() {
      this.getData();
    },
    async getData() {
      const data = await axios
        .post("/api/manage/card/getShipOrder", {
          keyword: this.keyword,
          state: this.state,
          page: this.page + 1,
          size: this.size
        })
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) return;
      this.list = this.list.concat(data.list);

      this.loading = false;
      this.refreshing = false;
      this.finished = data.list.length < this.size;
      this.page++;
    }
  }
});
